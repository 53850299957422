import { put, delay, call } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { clearTwofactorAuthData } from "../../../helpers/helpers";
import {
	activateAccountSuccess,
	activateAccountFail,
	isSpinning,
	twoFactorLoginFail,
	getTwoFactorQrCodeSuccess,
	authOTPAccountVerificationSuccess,
	OTPActivateAccountFail,
	changeAccountPhonenumberSuccess,
	changeAccountPhonenumberFail,
} from "../../actions/activateAccount/activateAccountActions";
import { authStart } from "../../actions/index";
import {
	ACTIVATE_ACCOUNT_API,
	VERIFY_TWO_FACTOR_USER_API,
	VERIFY_TWO_FACTOR_LOGIN_API,
	GET_TWO_FACTOR_QR_CODE,
	POST_RESEND_OTP_API,
	POST_VERIFY_OTP_API,
	CHANGE_ACCOUNT_PHONENUMBER_API,
} from "../../actions/apiCollections";

import { authSuccess, checkAuthTimeout, setAlert } from "../../actions/index";

import { getCurrentUserSaga } from "../getCurrentUserSaga";
import {
	getCampaignSettingsSaga,
	getSiteSettingsSaga,
} from "../siteSettingsSaga";

const lng =
	localStorage.getItem("i18nextLng") !== "dev"
		? localStorage.getItem("i18nextLng")
		: "en";

export function* activateAccountSaga(action) {
	yield put(isSpinning(true));
	try {
		const response = yield axios.get(
			ACTIVATE_ACCOUNT_API + action.userId + "/" + action.verificationId,
			{ params: { lng: lng } }
		);
		if (response.data.message?.authData?.url) {
			yield put(activateAccountSuccess(response.data.message));
			yield put(isSpinning(false));
		} else {
			yield put(activateAccountSuccess(response.data.data.user));
			const expirationTokenTime = 10800;
			const expirationDate = yield new Date(
				new Date().getTime() + expirationTokenTime * 1000
			);
			yield localStorage.setItem("token", response.data.token);
			yield localStorage.setItem("expirationDate", expirationDate);
			yield localStorage.setItem("email", action.email);
			yield localStorage.setItem("slug", response.data.data.user.slug);
			yield localStorage.setItem("userType", response.data.data.user.userType);
			yield call(getCurrentUserSaga);
			yield call(getCampaignSettingsSaga);
			yield call(getSiteSettingsSaga);
			yield put(authSuccess(response.data.token));
			yield put(checkAuthTimeout(expirationTokenTime));
			yield delay(500);
			yield put(setAlert(true));
			yield delay(8000);
			yield put(setAlert(false));
			yield put(isSpinning(false));
		}
	} catch (error) {
		yield put(activateAccountFail(error.response.data.message));
	}
}

export function* twoFactorUserActivateAccountSaga(action) {
	try {
		const response = yield axios.post(VERIFY_TWO_FACTOR_USER_API, action, {
			params: { lng: lng },
		});

		yield put(activateAccountSuccess(response.data.data.user));
		const expirationTokenTime = 10800;
		const expirationDate = yield new Date(
			new Date().getTime() + expirationTokenTime * 1000
		);
		yield localStorage.setItem("token", response.data.token);
		yield localStorage.setItem("expirationDate", expirationDate);
		yield localStorage.setItem("email", action.email);
		yield localStorage.setItem("slug", response.data.data.user.slug);
		yield localStorage.setItem("userType", response.data.data.user.userType);
		yield call(getCurrentUserSaga);
		yield call(getCampaignSettingsSaga);
		yield call(getSiteSettingsSaga);
		yield put(authSuccess(response.data.token));
		yield put(checkAuthTimeout(expirationTokenTime));
		yield delay(500);
		yield put(setAlert(true));
		yield delay(8000);
		yield put(setAlert(false));
	} catch (error) {
		yield put(activateAccountFail(error.response.data.message));
	}
}

export function* twoFactorLoginSaga(action) {
	yield put(authStart());
	yield put(isSpinning(true));
	try {
		const response = yield axios.post(VERIFY_TWO_FACTOR_LOGIN_API, action, {
			params: { lng: lng },
		});

		yield put(activateAccountSuccess(response.data.data.user));
		const expirationTokenTime = 10800;
		const expirationDate = yield new Date(
			new Date().getTime() + expirationTokenTime * 1000
		);

		yield localStorage.setItem("token", response.data.token);
		yield localStorage.setItem("expirationDate", expirationDate);
		yield localStorage.setItem("email", action.email);
		yield localStorage.setItem("slug", response.data.data.user.slug);
		yield localStorage.setItem("userType", response.data.data.user.userType);
		yield call(getCurrentUserSaga);
		yield call(getCampaignSettingsSaga);
		yield call(getSiteSettingsSaga);
		yield put(authSuccess(response.data.token));
		yield put(checkAuthTimeout(expirationTokenTime));
		yield localStorage.removeItem("twoFactorLoginStatus");
		yield localStorage.removeItem("twoFactorLoginQRLink");
		yield localStorage.removeItem("twoFactorLoginUserEmail");
		yield clearTwofactorAuthData();

		yield delay(500);
		yield put(setAlert(true));
		yield delay(8000);
		yield put(setAlert(false));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(twoFactorLoginFail(error.response.data.message));
		yield put(isSpinning(false));
	}
}

export function* getTwoFactorQrCodeSaga(action) {
	try {
		const response = yield axios.get(GET_TWO_FACTOR_QR_CODE);
		yield put(getTwoFactorQrCodeSuccess(response.data.message.url));
	} catch (error) {
		console.log(error);
	}
}

// OTP Verification

export function* authOTPAccountVerificationSaga(action) {
	try {
		const response = yield axios.patch(
			POST_RESEND_OTP_API,
			{ id: action.userId },
			{
				params: { lng: lng },
			}
		);
		yield put(authOTPAccountVerificationSuccess(response.data.data.data));
	} catch (error) {
		yield put(activateAccountFail(error.response.data.message));
	}
}

export function* authOTPActivateAccountSaga(action) {
	yield put(authStart());
	yield put(isSpinning(true));
	try {
		const response = yield axios.patch(
			POST_VERIFY_OTP_API,
			{ id: action.userId, otp: action.code },
			{
				params: { lng: lng },
			}
		);

		yield put(activateAccountSuccess(response.data.data.user));
		const expirationTokenTime = 10800;
		const expirationDate = yield new Date(
			new Date().getTime() + expirationTokenTime * 1000
		);

		yield localStorage.setItem("token", response.data.token);
		yield localStorage.setItem("expirationDate", expirationDate);
		yield localStorage.setItem("email", action.email);
		yield localStorage.setItem("slug", response.data.data.user.slug);
		yield localStorage.setItem("userType", response.data.data.user.userType);
		yield call(getCurrentUserSaga);
		yield call(getCampaignSettingsSaga);
		yield call(getSiteSettingsSaga);
		yield put(authSuccess(response.data.token));
		yield put(checkAuthTimeout(expirationTokenTime));
		yield localStorage.removeItem("twoFactorLoginStatus");
		yield localStorage.removeItem("twoFactorLoginQRLink");
		yield localStorage.removeItem("twoFactorLoginUserEmail");
		yield localStorage.removeItem("registerUserId");
		yield localStorage.removeItem("isForeignerInvestor");
		yield localStorage.removeItem("timerEndTime");
		yield clearTwofactorAuthData();

		yield delay(500);
		yield put(setAlert(true));
		yield delay(8000);
		yield put(setAlert(false));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(OTPActivateAccountFail(error.response.data.message));
		yield put(isSpinning(false));
	}
}

export function* changeAccountPhonenumberSaga(action) {
	yield put(isSpinning(true));
	try {
		const response = yield axios.patch(
			CHANGE_ACCOUNT_PHONENUMBER_API,
			{ id: action.userId, phoneNumber: action.phoneNumber },
			{
				params: { lng: lng },
			}
		);
		yield put(changeAccountPhonenumberSuccess(response.data.data.data));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(changeAccountPhonenumberFail(error.response.data.message));
		yield put(isSpinning(false));
	}
}
