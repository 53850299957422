export const ACTIVATE_ACCOUNT_START =
	"[Email Activation] Activate Account Start";
export const ACTIVATE_ACCOUNT = "[Email Activation] Activate Account";
export const ACTIVATE_ACCOUNT_SUCCESS =
	"[Email Activation] Activate Account Success";
export const ACTIVATE_ACCOUNT_FAIL = "[Email Activation] Activate Account Fail";
export const IS_SPINNING = "[GLOBAL] IS SPINNING";
export const SHOW_ALERT = "[GLOBAL] SHOW ALERT";

export const AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT =
	"AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT";
export const AUTH_TWO_FACTOR_LOGIN = "AUTH_TWO_FACTOR_LOGIN";
export const AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT_SUCCESS =
	"AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT_SUCCESS";
export const CLEAR_ERROR_AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT =
	"CLEAR_ERROR_AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT";
export const TWO_FACTOR_LOGIN_FAIL = "TWO_FACTOR_LOGIN_FAIL";
export const CLEAR_TWO_FACTOR_LOGIN_FAIL = "CLEAR_TWO_FACTOR_LOGIN_FAIL";
export const GET_TWO_FACTOR_QR_CODE = "GET_TWO_FACTOR_QR_CODE";
export const GET_TWO_FACTOR_QR_CODE_SUCCESS = "GET_TWO_FACTOR_QR_CODE_SUCCESS";

// OTP Verification Actions
export const AUTH_OTP_ACCOUNT_VERIFICATION = "AUTH_OTP_ACCOUNT_VERIFICATION";
export const AUTH_OTP_ACCOUNT_VERIFICATION_SUCCESS =
	"AUTH_OTP_ACCOUNT_VERIFICATION_SUCCESS";
export const CLEAR_OTP_ACCOUNT_VERIFICATION_FAIL =
	"CLEAR_OTP_ACCOUNT_VERIFICATION_FAIL";

export const AUTH_OTP_ACTIVATE_ACCOUNT = "AUTH_OTP_ACTIVATE_ACCOUNT";
export const OTP_ACTIVATE_ACCOUNT_FAIL = "OTP_ACTIVATE_ACCOUNT_FAIL";
export const CLEAR_OTP_ACTIVATE_ACCOUNT_FAIL =
	"CLEAR_OTP_ACTIVATE_ACCOUNT_FAIL";

export const CHANGE_ACCOUNT_PHONENUMBER = "CHANGE_ACCOUNT_PHONENUMBER";
export const CHANGE_ACCOUNT_PHONENUMBER_SUCCESS =
	"CHANGE_ACCOUNT_PHONENUMBER_SUCCESS";
export const CHANGE_ACCOUNT_PHONENUMBER_FAIL =
	"CHANGE_ACCOUNT_PHONENUMBER_FAIL";
