import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
	REGISTRATION_API,
	USER_TYPE_API,
	SUBSCRIBE_NEWSLETTER,
} from "../actions/apiCollections";
import {
	authRegistrationStart,
	authRegistrationSuccess,
	authRegistrationFail,
	userTypesSuccess,
	subscribeNewsletterSuccess,
} from "../actions/index";

import { getLanguageCode, getLanguageId } from "../../helpers/helpers";

export function* userRegistrationSaga(action) {
	yield put(authRegistrationStart());
	try {
		const response = yield axios.post(
			`${REGISTRATION_API}?lng=${getLanguageCode()}`,
			action.userData
		);
		yield put(authRegistrationSuccess(response.data.data.data));
	} catch (error) {
		yield put(authRegistrationFail(error.response.data.errors[0].msg));
	}
}

export function* userTypeSaga(action) {
	const id = action.filterData ? `${action.filterData}/` : "";
	try {
		const response = yield axios.get(`${USER_TYPE_API}${id}front`, {
			params: {
				...(getLanguageId() !== null && {
					language: getLanguageId(),
				}),
			},
		});
		yield put(
			userTypesSuccess(
				id ? response.data.data.data : response.data.data.data.docs
			)
		);
	} catch (error) {
		console.log(error);
		// yield put(authRegistrationFail(error.response.data.errors[0].msg));
	}
}

export function* subscribeNewsletterSaga(action) {
	try {
		const response = yield axios.post(
			`${SUBSCRIBE_NEWSLETTER}?lng=${getLanguageCode()}`,
			action.postData
		);
		yield put(subscribeNewsletterSuccess(response.data));
	} catch (error) {
		console.log(error);
	}
}
